<script>
import { mapGetters } from 'vuex'

import PageSubmenu from '@/components/PageSubmenu.vue'

export default {
  components: { PageSubmenu },
  computed: {
    ...mapGetters(['domain']),
    links () {
      const links = [
        {
          label: `Actualités ${this.domain?.label}`,
          path: '/app/articles',
          roles: ['admin', 'director', 'community-manager', 'operator']
        },
        // {
        //   label: 'Média droit social',
        //   path: '/app/articles/universals',
        //   roles: ['admin', 'director', 'community-manager', 'operator']
        // },
        this.domain?.slug !== 'media-mvp' && {
          label: `Média Social`,
          path: '/app/articles/media-mvp',
          roles: ['admin', 'director', 'community-manager', 'operator']
        }
      ]
      return links
    }
  },
  created () {

  }
}
</script>

<template>
  <div>
    <PageSubmenu :links="links" />
  </div>
</template>
